.modal {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    pointer-events: none;
    transition: all .4s ease-in;

    &.active {
        opacity: 1;
        pointer-events: all;
        backdrop-filter: blur(5px);
    }

    &__content {
        width: 600px;
        height: 706px;

        &.congratulations {
            width: 400px;
            height: 291px;

            @media screen and (max-width: $tableWidth) {
                width: 288px;
                height: 209px;
            }

            .congratulations_modal {
                h2 {
                    margin-top: 78px;
                    font-size: rem(18);

                    @media screen and (max-width: $tableWidth) {
                        margin-top: 50px;
                    }

                    @media screen and (max-width: 1920px) {
                        font-size: rem(24);
                    }
                    font-weight: 600;
                    line-height: 122.2%;
                    text-align: center;
                    color: #F5DF4D;
                    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                }

                .text {
                    font-weight: 300;
                    font-size: rem(22);
                    @media screen and (max-width: 1920px) {
                        font-size: rem(26);
                    }
                    color: #F5DF4D;
                    line-height: 122.2%;
                    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                    margin-bottom: 32px;
                }

                .btn {
                    padding: 17px 62px;

                    @media screen and (max-width: $tableWidth) {
                        padding: 12px 43px;
                    }
                }
            }
        }

        &.DMP {
            width: 400px;
            height: 291px;

            @media screen and (max-width: $tableWidth) {
                width: 288px;
                height: 209px;
            }

            .DMP_modal {
                .text {
                    margin-top: 78px;
                    text-align: center;
                    font-weight: 400;
                    font-size: 18px;
                    @media screen and (max-width: 1920px) {
                        font-size: rem(22);
                    }
                    color: #F5DF4D;
                    line-height: 122.2%;
                    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                    margin-bottom: 32px;

                    @media screen and (max-width: $tableWidth) {
                        margin-top: 50px;
                        font-size: 18px;
                        margin-bottom: 20px;
                    }
                }
    
                .btn {
                    padding: 17px 62px;

                    @media screen and (max-width: $tableWidth) {
                        padding: 12px 43px;
                    }
                }
            }

            
        }


        @media screen and (max-width: 1920px) {
            width: 400px;
            height: 506px;
        }

        @media screen and (max-width: $tableWidth) {
            width: 288px;
            height: 364px;
        }

        @media screen and (max-width: $tableWidth) and (max-height: 600px) {
            width: 288px;
            height: 364px;
        }

        position: relative;
        transform: scale(0.5);
        transition: all .4s;
        &.active {
            transform: scale(1);
        }
    }



    &__img {
        width: 100%;
        height: 100%; 
        position: absolute;
        top: 0;
        left: 0;
    } 

    .content_block {
        z-index: 9999;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .wax__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .modal__icon {
                top: 0;
                left: calc(50% - 150px);
            } 

            @media screen and (max-width: $tableWidth) {
                svg {
                    width: 245px;
                    height: 142px;
                }
            }

            span {
                text-align: center;
                font-size: rem(24);
                margin-top: -128px;
                margin-bottom: 44px;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);

                @media screen and (max-width: $tableWidth) {
                    font-size: rem(20);
                    margin-top: -78px;
                }
            }
        }

        .link__create {
            color: #968E53;
            text-decoration: underline;
            font-size: rem(12);
            transition: all .2s ease-in;

            @media screen and (max-width: $tableWidth ) {
                margin-top: -20px;
            }

            &:hover {
                color: #968E53;
            }

            &.indent {
                margin-top: 24px;
                @media screen and (max-width: $tableWidth ) {
                    margin-top: -4px;
                }
            }
        }

        .wax__form {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 24px;
            flex-wrap: wrap;
            margin-top: 32px;

            @media screen and (max-width: $tableWidth ) {
                margin-top: 10px;
            }

            .form__input {
                width: 100%;
                border-top: 0.5px solid #4E4506;
                border-bottom: 0.5px solid #4E4506;
                color: #968E53;
                padding: 13px;
                font-size: rem(16);
                background: none;

                @media screen and (max-width: $tableWidth ) {
                    font-size: 16px;
                }

                &::placeholder {
                    color: #968E53;
                    font-size: rem(16);
                    @media screen and (max-width: $tableWidth ) {
                        font-size: 16px;
                    }
                }
            }

            .btn {
                margin-top: 22px;
                margin-bottom: 14px;
                font-weight: 600;
                font-size: rem(12);
                letter-spacing: 0.075em;
                text-transform: uppercase;
                padding: 19px 41px 14px 41px;
                border-color: $mainFontColor;

                @media screen and (max-width: $tableWidth ) {
                    font-size: 10px;
                    padding: 12px 27px;
                }
            }
        }

    }

    .unstake_modal {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 40px;

        @media screen and (max-width: $tableWidth) {
            padding: 0 20px;
        }

        h2 {
            font-size: rem(20);
            font-weight: 500;
            color: $mainFontColor;
            letter-spacing: 0.04em;
            text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
            margin-top: 100px;
            margin-bottom: 95px;

            @media screen and (max-width: $tableWidth) {
                font-size: rem(16);
            }
        }

        .button_blocks {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .btn {
                font-weight: 600;
                font-size: rem(12);
                padding: 17px 62px;
                letter-spacing: 0.075em;
                @media screen and (max-width: $tableWidth) {
                    padding: 12px 32px;
                }
                border-color: $mainFontColor;
            }
        }
    }
}

.claim_modal {
    padding: 70px 40px;
    @media screen and (max-width: $tableWidth ) {
        padding: 46px 28px;
    }
    .claim {
        width: 100%;

        &__title {
            font-size: rem(32);
            font-weight: 800;

            @media screen and (max-width: $tableWidth) {
                font-size: rem(28);
                font-weight: 600;
            }
        }

        &__text {
            font-size: rem(16);
        }
    }

    .information {
        width: 100%;
        margin-top: 64px;
        margin-bottom: 55px;
        @media screen and (max-width: $tableWidth ) {
            margin-top: 32px;
            margin-bottom: 33px;
        }
        font-size: rem(16);
        .line {
            display: flex;
            margin-bottom: 5px;

            @media screen and (max-width: $tableWidth ) {
                justify-content: space-between;
            }

            .name, .value {
                width: 50%;
            }

            @media screen and (max-width: $tableWidth ) {
                .value {
                    width: 30%;
                }
            }
        }
    }

    .button_blocks {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        // .btn {
        //     font-weight: 600;
        //     padding: 17px 62px;
        //     @media screen and (max-width: $tableWidth) {
        //         padding: 12px 32px;
        //     }
        //     border-color: $mainFontColor;
        // }

        .btn {
            font-weight: 600;
            font-size: rem(12);
            padding: 17px 50px;
            @media screen and (max-width: $tableWidth) {
                padding: 12px 32px;
            }
            border-color: $mainFontColor;
        }
    }

}

.staking_modal {
    padding: 70px 40px;
    font-size: rem(16);

    @media screen and (max-width: $tableWidth) {
        padding: 70px 20px;
    }
    
    .staking {
        width: 100%;
        &__title {
            font-size: rem(30);
            font-weight: 600;
            margin-bottom: 30px;
            text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
            @media screen and (max-width: $tableWidth) {
                font-size: rem(28);
                font-weight: 600;
            }
        }
    }

    .staking_list {
        width: 100%;
        display: flex;
        align-items: center;

        .title {
            width: 60%;
        }
        margin-bottom: 44px;

        @media screen and (max-width: 1920px) {
            margin-bottom: 24px;
        }

        @media screen and (max-width: $tableWidth) {
            margin-bottom: 16px;
        }

        &:last-child {
            margin-bottom: 140px;
            @media screen and (max-width: 1920px) {
                margin-bottom: 90px;
            }
            @media screen and (max-width: $tableWidth) {
                margin-bottom: 30px;
            }
        }
    }

    .staking_content {
        width: 100%;
    }

    .time_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        @media screen and (max-width: $tableWidth) {
            margin-bottom: 12px;
        }

        align-items: center;

        &:last-child {
            margin-bottom: 81px;
        }

        .time__wrapper {
            height: 40px;
            width: 150px;
            @media screen and (max-width: $tableWidth) {
                width: 120px;
            }
            border: 1px solid #968E53;
            box-sizing: border-box;
            color: $mainFontColor;
        }
    }

    .time__toggle{
        position: relative;
        cursor: pointer;
        &.open{
            .selected_value{
                border-bottom-color: transparent;
                &::after{
                    transform: translateY(-30%) rotate(180deg) scale(3.4);

                    @media screen and (max-width: 1920px){
                        transform: translateY(-40%) rotate(180deg) scale(2);
                    }
                }
                span{
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        .selected_value {
            font-size: rem(16);
            padding: 15px 22px 15px 16px;
            border: 1px solid #968E53;
            cursor: pointer;
            width: 250px;

            @media screen and (max-width: 1920px) {
                width: 150px;
            }

            @media screen and (max-width: $tableWidth) {
                width: 120px;
                font-size: rem(14);
            }

            &::after {
                content: url('../../img/icons/arrow.svg');
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-80%) scale(3.4);

                @media screen and (max-width: 1920px){
                    right: 20px;
                    transform: translateY(-60%) scale(2);
                }
                z-index: 100;
            }            
        }
        .time__list{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: #000;

            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 250px;
            @media screen and (max-width: 1920px) {
                width: 150px;
            }
            @media screen and (max-width: $tableWidth) {
                width: 120px;
                font-size: rem(14);
            }
            transition: all .2s ease-in;
            padding: 50px 16px 15px 16px;
            padding: 50px 0 15px 0;
            border: 1px solid #968E53;
            z-index: 99;

            .time {
                transition: all .2s ease-in;
                padding: 8px 15px;
                cursor: pointer;
            }

            .time:hover {
                background-color: #968E53;
            }

            &.show {
                visibility: visible;
            }
        }
    }

    .button_blocks {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .btn {
            font-weight: 600;
            font-size: rem(12);
            padding: 17px 50px;
            @media screen and (max-width: $tableWidth) {
                padding: 12px 32px;
            }
            border-color: $mainFontColor;
        }
    }
}

