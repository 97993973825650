.unauthorized_content {
    width: 100%;
    margin-top: 3.5rem;
    color: $faqFontColor;
    font-size: rem(12);
    line-height: 14px;
    height: 60vh;
    overflow-y: auto;

    @media screen and (max-width: $tableWidth) {
        margin-top: 0;
        font-size: rem(18);
        line-height: 21px;
    }

    .unauthorized_wrapper{
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}


.information__container {
    width: 70%;

    @media screen and (max-width: $tableWidth) {
        width: 100%;
    }
}

.usage_information {
    padding-top: 10px;
    color: $faqFontColor;
    &__title h2 {
        margin-top: 0.4rem;
        font-size: rem(18);
        font-weight: 600;
        margin-bottom: 1.7rem;
        margin-left: -0.7rem;
        line-height: 21px;

        @media screen and (max-width: $tableWidth) {
            margin-left: 0;
            width: 79%;
            font-size: 18px;
            line-height: 21px;
        }

        @media screen and (max-width: $tableWidth) and (max-height: 553px) {
            font-size: 14px;
        }

        @media screen and (max-width: $smPhoneWidth) {
            font-size: 14px;
        }
    }

    &__list {

        @media screen and (max-width: $tableWidth) {
            font-size: 12px;
        }

        ul li {
            list-style: decimal;
            margin-bottom: 0.8rem;

            @media screen and (max-width: $tableWidth) {
                margin-left: 1rem;
            }
        }
    }
}

.connect_wallet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 160px;
    margin-top: 55px;
    cursor: pointer;

    @media screen and (max-width: $tableWidth) {
        display: none;
    }

    &__icon {
        width: 100px;
        height: 100px;
    }

    &__title {
        color: $mainFontColor;
        font-weight: 600;
        font-size: rem(24);
        letter-spacing: 0.14em;
        margin: 20px 0;
        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    }

    &__text {
        font-family: 'Mazzard';
        text-align: center;
        color: #968E53;
        font-size: rem(14);
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 19px;
    }

    .btn {
        font-family: 'Mazzard';
        font-size: rem(12);
        font-weight: 600;
        padding: 19px 41px;
        text-transform: uppercase;
        border: 1px solid #F5DF4D;
        letter-spacing: 0.075em;
    }
}