.main-screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    position: relative;

    .alert {
        position: absolute;
        top: 18%;
        left: 45.6%;
        transform: translate(-50%, -15px);
        margin: 0;

        @media screen and (max-width: 2660px) {
            top: calc(50% - 500px);
            left: 43%;
        }

        @media screen and (max-width: 2048px) {
            left: 44%;
            top: calc(50% - 500px);
        }

        @media screen and (max-width: 1920px ) {
            top: calc(50% - 400px);
            left: 43.5%;
        }

        @media screen and (max-width: 1680px ) {
            top: calc(50% - 380px);
            left: 43.5%;
        }

        @media screen and (max-width: 1600px ) {
            top: calc(50% - 350px);
            left: 43.5%;
        }

        @media screen and (max-width: 1536px ) {
            top: calc(50% - 350px);
            left: 43.5%;
        }

        @media screen and (max-width: 1440px ) {
            top: calc(50% -  300px);
            left: 42%;
        }

        @media screen and (max-width: 1366px) {
            top: calc(50% - 300px);
            left: 42%;
        }

        @media screen and (max-width: 1280px) {
            top: calc(50% - 280px);
            left: 40.5%;
        }

        @media screen and (max-width: 1024px) and (max-height: 1366px) {
            top: 10%;
            left: 38%;
        }
    
        @media screen and (max-width: 1024px) and (max-height: 900px) {
            top: -7%;
            left: 38%;
        }



        @media screen and (max-width: $tableWidth ) and (max-height: 1024px) {
            top: 13%;
            left: 50%;
        }

        @media screen and (max-width: $tableWidth ) and (max-height: 860px) {
            top: 12%;
        }

        @media screen and (max-width: $tableWidth ) and (max-height: 812px) {
            top: 12%;
            left: 50%;
        }

        @media screen and (max-width: $tableWidth ) and (max-height: 736px) {
            top: 10%;
        }

        @media screen and (max-width: $tableWidth ) and (max-height: 667px) {
            top: 9%;
        }

        @media screen and (max-height: 568px) {
            top: 6%;
            font-size: 14px;
            left: 51%;
        }
    }

    &.shelter {
        width: 120vw;
        transition: transform .3s ease-in;

        .alert {
            top: 0;
            left: calc(50% - 18vw);
            transform: translate(-50%, 0);

            .alert__screen {
                top: -34%;
            }

            @media screen and (max-width: 2660px) {
                top: -1%;
                left: calc(50% - 23vw);
                .alert__screen {
                    top: -41%;
                }
            }

            @media screen and (max-width: 1920px) {
                left: calc(50% - 23vw);
                top: -9%;
                .alert__screen {
                    top: -32%;
                }
            }

            @media screen and (max-width: 1440px) {
                top: -10%;
                left: calc(50% - 25vw);

                .alert__screen {
                    top: -51%;
                }
            }

            @media screen and (max-width: 1366px) {
                top: -14%;
                left: calc(50% - 25vw);

                .alert__screen { 
                    top: -40%;
                }
            }

            @media screen and (max-width: 1024px) {
                top: -16%;
                left: calc(50% - 28vw);
            }

            @media screen and (max-width: 1024px) and (max-height: 1366px) {
                top: -4%;
                left: calc(50% - 28vw);
            }
        
            @media screen and (max-width: 1024px) and (max-height: 900px) {
                top: -16%;
                left: calc(50% - 28vw);
            }

            @media screen and (max-width: $tableWidth) {
                top: 6%;
                left: 42%;

                .alert__screen { 
                    top: -29%;
                }
            }

            @media screen and (max-width: $tableWidth) and (max-height: 568px) {
                top: 6%;
                left: 42%;

                .alert__screen { 
                    top: -39%;
                }
            }

            @media screen and (max-width: 375px) and (max-height: 560px) {
                .alert__screen { 
                    top: -36%;
                }
            }
        }

        &.scroll__shelter {
            transform: translateX(-53vw);
        }

        @media screen and (max-width: $tableWidth) {
            .show-btn {
                width: 39vh;
                top: unset;
                bottom: 0;
                left: 17.2vw;
                transform: translate(0, 0);
            }

            .rewards {
                width: 30.5vw;
                top: unset;
                bottom: 40px;
                left: 85vw;
                transform: translate(0, 0);
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 1024px) {
            .show-btn {
                width: 39vh;
                top: unset;
                bottom: 0;
                left: 25.2vw;
                transform: translate(0, 0);
            }

            .rewards {
                width: 30.5vw;
                top: unset;
                bottom: 40px;
                left: 88vw;
                transform: translate(0, 0);
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 950px) {
            .show-btn {
                width: 36.5vh;
                top: unset;
                bottom: 0;
                left: 12.2vw;
                transform: translate(0, 0);
            }

            .rewards {
                width: 40.5vw;
                top: unset;
                bottom: 80px;
                left: 85vw;
                transform: translate(0, 0);
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 812px) {
            .show-btn {
                width: 37vh;
                top: unset;
                bottom: 0;
                left: 9vw;
                transform: translate(0, 0);
            }

            .rewards {
                width: 36.5vw;
                top: unset;
                bottom: 70px;
                left: 89vw;
                transform: translate(0, 0);
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 736px) {
            .show-btn {
                width: 36.5vh;
                top: unset;
                bottom: 0;
                left: 17.2vw;
                transform: translate(0, 0);
            }

            .rewards {
                width: 36.5vw;
                top: unset;
                bottom: 40px;
                left: 85vw;
                transform: translate(0, 0);
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 705px) {
            .show-btn {
                width: 36.5vh;
                top: unset;
                bottom: 0;
                left: 16.2vw;
                transform: translate(0, 0);
            }

            .rewards {
                width: 36.5vw;
                top: unset;
                bottom: 40px;
                left: 85vw;
                transform: translate(0, 0);
            }
        }

        @media screen and (max-width: $tableWidth) and (max-height: 553px) {
            .show-btn {
                width: 29.5vh;
                top: unset;
                bottom: 0;
                left: 27.2vw;
                transform: translate(0, 0);
            }

            .rewards {
                width: 32.5vw;
                top: unset;
                bottom: 40px;
                left: 85vw;
                transform: translate(0, 0);
            }
        }

        @media screen and (max-width: 375px) and (max-height: 560px) {
            .show-btn {
                width: 29.5vh;
                top: unset;
                bottom: 0;
                left: 26.2vw;
                transform: translate(0, 0);
            }

            .rewards {
                width: 32.5vw;
                top: unset;
                bottom: 40px;
                left: 85vw;
                transform: translate(0, 0);
            }
        }

    }


    &.main-screen__hide-int {
        .alert {
            position: absolute;
            top: 15%;
            left: 49.6%;
            transform: translate(-50%, -15px);
            margin: 0;

            @media screen and (max-width: 2660px) {
                top: calc(50% - 580px);
            }

            // @media screen and (max-width: 2048px) {
            //     top: 8%;
            // }

            @media screen and (max-width: 1920px ) {
                top: 0;
            }

            @media screen and (max-width: 1536px ) {
                top: calc(50% - 420px);
            }

            @media screen and (max-width: 1440px ) {
                top: calc(50% - 370px);
            }

            @media screen and (max-width: 1366px ) {
                top: calc(50% - 380px);
            }

            @media screen and (max-width: 1280px) {
                top: 0;
                left: 48.5%;
            }
        }

        &.shelter {
            .alert {
                position: absolute;
                top: 2%;
                left: 38%;
                transform: translate(-50%, -15px);
                margin: 0;
    
                @media screen and (max-width: 2660px) {
                    top: 0;
                    left: 38%;
                }
    
                @media screen and (max-width: 2048px) {
                    top: 8%;
                }
    
                @media screen and (max-width: 1920px ) {
                    top: -3%;
                }
    
                @media screen and (max-width: 1440px ) {
                    top: -17%;
                }
    
                @media screen and (max-width: 1366px ) {
                    top: -10%;
                }
    
                @media screen and (max-width: 1280px) {
                    top: -4%;
                }
            } 
        }
    }

    .scroll_page__right {
        display: none;
        top: 50%;
        position: absolute;
        background: none;
        padding-right: 16px;
        right: 17%;
        
        svg {
            width: 20px;
            height: 36px;
        }

        &.scroll_right {
            display: block;
        }
    }

    .scroll_page__left {
        display: none;

        position: absolute;
        top: 50%;
        background: none;
        padding-left: 16px;
        right: 47%;

        svg {
            width: 20px;
            height: 36px;
        }

        &.scroll_left {
            display: block;
        }
    }
}