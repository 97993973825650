.information_page, .nft_page__content, .stats_page, .homeshelter_page__content, .menu_block {
    padding-top: 2px;
    height: 92%;

    @media screen and (max-width: 1920px) {
        height: 95%;
    }

    @media screen and (max-width: $tableWidth) and (max-height: 553px) {
        height: 92%;
    }
}

.menu_block {
    overflow: visible;
}

.faq_list {
    width: 100%;
    margin-top: 100px;
    color: $faqFontColor;
    line-height: 14px;
    height: 60vh;

    @media screen and (max-width: 1536px ) {
        height: 54vh;
    }

    @media screen and (max-width: 1440px) {
        height: 54vh;
    }

    @media screen and (max-width: 1366px) {
        margin-top: 20px;
        height: 65vh;
    }

    @media screen and (max-width: $tableWidth) {
        height: 52vh;
        margin-top: 0;
    }

    @media screen and (max-width: $tableWidth) and (max-height: 553px) {
        height: 44vh;
        margin-top: 0;
    }

    @media screen and (max-width: 375px) and (max-height: 555px) {
        height: 42vh;
    }

    @media screen and (max-width: $smPhoneWidth) {
        height: 49vh;
        margin-top: 0;
    }

    overflow-y: auto;
    &_wrap{
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 20px;
    }
    .faq_item {
        margin-bottom: 50px;
        width: 70%;

        @media screen and (max-width: 1920px) {
            margin-bottom: 20px;
        }

        @media screen and (max-width: $tableWidth) {
            width: 100%;
            margin-bottom: 0;
        }

        
        &__title {
            font-weight: 500;
            line-height: 14px;
            font-size: rem(12);
            margin-bottom: 25px;
            position: relative;
            padding-left: 20px;
            cursor: pointer;
            letter-spacing: 0.04em;
            color: $faqFontColor;

            &.active, &.big {
                font-size: rem(12);
            }

            @media screen and (max-width: $tableWidth) {
                font-size: 12px;
            }

            &::before {
                content: url('../../img/icons/arrow_bot.svg');
                opacity: 1;
                visibility: visible;
                position: absolute;
                left: 0;
                top: calc(50% - 9px);
                transform: scale(1.2);
                transition: transform .2s ease-in;
            }

            &.active::before {
                transform: rotate(180deg) scale(1.2);
            }
        }

        &__content {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            font-weight: 400;
            transition: all .3s ease-in;
            font-size: rem(12);
            padding-left: 20px;
            line-height: 14px;

            @media screen and (max-width: 2660px) {
                line-height: .9rem;
            }

            @media screen and (max-width: $tableWidth) {
                font-size: 12px;
                line-height: 102.2%;
            }

            &.active {
                max-height: 20vh;
                opacity: 1;

                @media screen and (max-width: $tableWidth) {
                    // height: 14vh;
                    padding-left: 16px;
                    line-height: 102.2%;
                    padding-bottom: 10px;
                }
            }
        }

    }
}  