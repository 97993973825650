.alert {
    width: 950px;
    height: 500px;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &__screen {
        position: absolute;
        top: -58%;
        left: 0;
        width: 100%;
        height: auto;
    }

    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 69;
        margin-left: -20px;

        position: relative;

        .label {
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translateX(-46%);
            color: #FFFAD8;
            font-weight: 600;
            font-size: rem(20);
            line-height: 24px;
            text-shadow: 0px 4px 20px #000000;

            @media screen and (max-width: 1440px) {
                top: 3%;
            }

            @media screen and (max-width: $tableWidth) {
                top: 13%;
            }

            @media screen and (max-width: 360px) {
                font-size: rem(18);
            }
        }
    }

    @media screen and (max-width: 2660px) {
        width: 567px;
        height: 310px;
    }

    @media screen and (max-width: 2048px) {
        width: 620px;
        height: 310px;
        &_content {
            padding-top: 40px;
        }
    }

    @media screen and (max-width: 1920px) {
        width: 419px;
        height: 250px;
        &_content {
            padding-top:0;
            margin-left: -10px;
        }
    }

    @media screen and (max-width: 1440px) {
        width: 316px;
        height: 156px;
        &_content{
            // margin-left: -45px;
        }
    }

    @media screen and (max-width: 1366px) {
        width: 310px;
        height: 180px;
    }

    @media screen and (max-width: 1280px) {
        width: 280px;
        height: 160px;
    }

    @media screen and (max-width: 1024px) and (max-height: 1366px) {
        width: 460px;
        height: 240px;
    }

    @media screen and (max-width: 1024px) and (max-height: 900px) {
        width: 260px;
        height: 140px;
    }

    // @media screen and (max-width: 1024px) {
    //     width: 260px;
    //     height: 140px;
    // }

    @media screen and (max-width: $tableWidth ) {
        width: 336px;
        height: 180px;
    }

    @media screen and (max-width: $tableWidth ) and (max-height: 1024px) {
        width: 436px;
        height: 250px;
    }

    @media screen and (max-width: $tableWidth ) and (max-height: 950px) {
        width: 336px;
        height: 200px;
    }

    @media screen and (max-width: $tableWidth ) and (max-height: 812px) {
        width: 336px;
        height: 200px;
    }

    @media screen and (max-width: $tableWidth ) and (max-height: 736px) {
        width: 300px;
        height: 180px;
    }

    @media screen and (max-width: $tableWidth ) and (max-height: 667px) {
        width: 285px;
        height: 168px;
    }


    @media screen and (max-width: $smPhoneWidth ) {
        width: 236px;
        height: 126px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    font-size: rem(16);
    // font-size: 16px;

    &__caption {
        text-transform: uppercase;
        font-weight: 600;
    }

    &__icon {
        margin-bottom: 10px;
    }
}

.alert.login {
    // background-image: url('../../img/alert/login.png');

    color: $alertLoginColor;
    text-shadow: $alertLoginColor 1px 0 10px;
}

.alert.success {
    // background-image: url('../../img/alert/success.png');

    color: $alertSuccessColor;
    text-shadow: 0px 0px 10px $alertSuccessColor;
}

.alert.auth {
    // background-image: url('../../img/alert/auth.png');
    
    color: $alertLoginColor;
    text-shadow: 0px 0px 10px $alertAuthColor;
    transition: transform .2s ease-in;

    &:hover {
        transform: translateX(-50%) scale(1.1);
    }
}

.alert.warning {
    // background-image: url('../../img/alert/warning.png');

    color: $alertWarningColor;
    text-shadow: 0px 0px 10px $alertWarningColor;
}