.card-block {
    width: 100%;
    color: $faqFontColor;
    font-size: rem(12);
    line-height: 14px;
    margin-top: 5px;
    position: relative;
    height: 100%;

    .container {
        width: 98.2%;
        margin: 0 auto;
        position: relative;
        z-index: 69;

        @media screen and (max-width: $tableWidth) {
            width: 100%;
            overflow-y: auto;
            height: 98%;
        }
    }

    @media screen and (max-width: $tableWidth) {
        background: rgba(97, 157, 183, 0.1);
    }

    .img__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        height: calc(100vh - 340px);
        
        @media screen and (max-width: 2660px) and (max-height: 2000px) {
            top: 1%;
        }

        @media screen and (max-width: 2660px) and (max-height: 1460px) {
            top: 2%;
        }
        
        @media screen and (max-width: 2660px) and (max-height: 1350px) {
            top: 1%;
        }

        @media screen and (max-width: 1920px) {
            height: calc(100vh - 255px);
        }
        @media screen and (max-width: 1536px) {
            height: calc(100vh - 72px);
        }
        @media screen and (max-width: 1440px) {
            height: calc(100vh - 195px);
        }

        @media screen and (max-width: 1024px) and (max-height: 1366px) {
            height: calc(100vh - 820px);
        }
    
        @media screen and (max-width: 1024px) and (max-height: 900px) {
            height: calc(100vh - 195px);
        }

        img {
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: $tableWidth) {
            display: none;
        }
    }

    .card__wrapper {
        height: 70vh;
        overflow-y: auto;
        // margin-top: 7%;

        @media screen and (max-width: 2560px) {
            height: 58vh;
        }

        @media screen and (max-width: 2660px) and (max-height: 2000px) {
            height: 58vh;
        }

        @media screen and (max-width: 2660px) and (max-height: 1460px) {
            height: 64vh;
        }
        
        @media screen and (max-width: 2660px) and (max-height: 1350px) {
            height: 58vh;
        }

        @media screen and (max-width: 1920px) and (max-height: 1200px) {
            height: 58vh;
        }

        @media screen and (max-width: 1920px) and (max-height: 1050px) {
            height: 56vh;
        }

        @media screen and (max-width: 1440px) {
            height: 54vh;
        }
        @media screen and (max-width: 1366px) {
            height: 50vh;
        }

        @media screen and (max-width: 1024px) and (max-height: 1366px) {
            height: 32vh;
        }
    
        @media screen and (max-width: 1024px) and (max-height: 900px) {
            height: 50vh;
        }

        @media screen and (max-width: $tableWidth) {
            overflow: visible;
        }
    }

    @media screen and (max-width: $tableWidth) {
        height: calc(100% - 20px);
    }

    @media screen and (max-width: $smPhoneWidth) {
        height: calc(100% - 30px);
    }

    &__wrapper {
        height: max-content;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        display: none;

        &.show {
            display: flex;
        }
        @media screen and (max-width: $tableWidth) {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

.sidebar_block {
    width: 100%;
    backdrop-filter: blur(5px);
}

.card-item {
    position: relative;
    width: calc( (100% - 90px) / 4); 
    margin-right: 30px;
    margin-bottom: 38px;
    background: url('../../img/card_inactive.png') no-repeat center;
    background-size: 100%;
    padding: 16px 18px;
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;

    @media screen and (max-width: 1200px) {
        width: calc( (100% - 60px) / 2); 
    }


    &.card-item__without {
        background: url('../../img/card-more.png') no-repeat center;
        background-size: 100%;
        // width: calc( (100% - 90px) / 4); 
        .card-item__img{
            visibility: hidden;
            opacity: 0;
        }
    }

    @media screen and (max-width: $tableWidth) {
        background: none;
        padding: 0;
    }
    &:nth-child(4n){
        margin-right: 0;
    }

    @media screen and (max-width: 1440px) {
        margin-right: 30px;
    }

    @media screen and (max-width: $tableWidth) {
        width: calc( (100% - 8px) / 2);
        margin-right: 8px;
        &:nth-child(even){
            margin-right: 0;
        }
        &.card-item__without {
            background: none;
            border: 1px solid rgba(170, 151, 16, 0.3);
        }
    }

    .show__more_wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(90% - 33px);
        height: calc(90% - 5px);
        transform: translate(-50%, -50%);
        // border: 1px solid rgba(170, 151, 16, 0.3);
        font-weight: 800;
        font-size: rem(24);
        color: #F5DF4D;
        text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);

        display: flex;
        align-items:center;
        justify-content: center;

        @media screen and (max-width: 2560px){
            width: calc(90% - 27px);
        }
        @media screen and (max-width: 1920px){         
            height: calc(90% - 20px);
        }
    }

    &__img {
        position: relative;
        margin-bottom: 12px;
        margin-top: 12px;
        @media screen and (max-width: $tableWidth) {
            margin-bottom: 0;
            margin-top: 0;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 90%;
            height: auto;
            filter: drop-shadow(0px 4px 10px #343122);
            border: 0.5px solid rgba(170, 151, 16, 0.3);

            @media screen and (max-width: 2580px) {
                width: 93%;
            }
        }        
    }

    .unstaked {
        position: absolute;
        top: 50%;
        left: 50%;

        opacity: 0;

        &.select {
            opacity: 1;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        width: calc( 90% - 33px);
        height: calc( 90% - 5px);
        @media screen and (max-width: 2560px) {
            width: calc( 90% - 27px);
        }
        @media screen and (max-width: 1920px) {
            height: calc( 90% - 20px);
        }
        @media screen and (max-width: 1440px) {
            height: calc( 90% - 31px);
        }
        @media screen and (max-width: $tableWidth) {
            height: calc(100% - -1px);
            width: calc(100% - 12px);
        }
        transform: translate(-50%,-50%);
        background: linear-gradient(0deg, rgba(85, 80, 56, 0.9), rgba(85, 80, 56, 0.9));
        box-shadow: 0px 4px 10px #343122;
        border: 0.5px solid rgba(170, 151, 16, 0.3);

        color: #F5DF4D;
        font-size: 14px;
        text-align: center;
        line-height: 1.2;

        .wrapper_stake {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            height: 100%;
            width: 90%;
            margin: 0 auto;
            padding: 2vh 0;

            @media screen and (max-width: 1600px) {
                width: 94%;
            }

            @media screen and (max-width: 1440px) {
                width: 94%;
            }

            @media screen and (max-width: 1366px) {
                width: 96%;
                padding: 1vh 0;
            }

            @media screen and (max-width: 1280px) {
                width: 100%;
                padding: 1vh 0;
            }

            @media screen and (max-width: $tableWidth) {
                width: 90%;
            }

            @media screen and (max-width: $smPhoneWidth) {
                width: 98%;
            }

            .title {
                font-size: rem(16);

                @media screen and(max-width: 1440px) {
                    font-size: rem(16);
                }

                @media screen and(max-width: 1366px) {
                    font-size: rem(14);
                }

                @media screen and (max-width: 1280px) {
                    padding-bottom: 2px;
                }


                @media screen and(max-width: $tableWidth) {
                    font-size: 14px;
                }
                @media screen and(max-width: $smPhoneWidth) {
                    font-size: 12px;
                }
                font-weight: 500;
                line-height: 122.2%;
                text-align: center;
                color: #F5DF4D;
                text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
                border-bottom: 1px solid #F5DF4D;
                padding-bottom: 10px;
            }

            .list {
                width: 100%;
                display: flex;
                flex-direction: column;
                font-size: rem(12);

                line-height: 14px;
                color: #F5DF4D;
                padding: 20px 0;
                @media screen and(max-width: 1440px) {
                    font-size: rem(12);
                    padding: 5px 0;
                }

                @media screen and(max-width: 1366px) {
                    font-size: rem(11);
                    padding: 5px 0;
                }

                @media screen and(max-width: $tableWidth) {
                    font-size: 12px;
                }

                @media screen and(max-width: $smPhoneWidth) {
                    font-size: 10px;
                }
                .item {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1.4vh;

                    @media screen and (max-width: 1440px) {
                        margin-bottom: .4vh;
                    }

                    
                    @media screen and (max-width: 1280px) {
                        margin-bottom: 0;
                    }


                    @media screen and (max-width: $tableWidth) {
                        margin-bottom: .4vh;
                    }

                    .text {
                        font-size: rem(14);
                        font-weight: 400;
                        color: $mainFontColor;
                        margin-bottom: 1vh;

                        @media screen and (max-width: 1440px) {
                            font-size: 11px;
                            margin-bottom: 4px;
                        }

                        @media screen and (max-width: 1366px) {
                            font-size: 10px;
                            margin-bottom: 4px;
                        }

                        @media screen and (max-width: 1280px) {
                            font-size: 9px;
                            margin-bottom: 0;
                        }

                        @media screen and (max-width: $tableWidth) {
                            font-size: 11px;
                            margin-bottom: 4px;
                        }

                        @media screen and (max-width: $smPhoneWidth) {
                            font-size: 10px;
                        }
                    }

                    .value {
                        font-size: rem(14);
                        font-weight: 600;
                        color: $mainFontColor;
                        @media screen and (max-width: 1440px) {
                            font-size: 11px;
                        }

                        @media screen and (max-width: 1366px) {
                            font-size: 10px;
                        }

                        @media screen and (max-width: 1280px) {
                            font-size: 9px;
                        }

                        @media screen and (max-width: $tableWidth) {
                            font-size: 11px;
                        }

                        @media screen and (max-width: $smPhoneWidth) {
                            font-size: 10px;
                        }
                    }

                    &:last-child {
                        margin-bottom: unset;
                    }
                }
            }

            .btn_container {
                padding-top: 25px;
                @media screen and (max-width: 1600px) {
                    padding-top: 9px;
                }
                @media screen and(max-width: 1440px) {
                    padding-top: 15px;
                }

                @media screen and(max-width: 1366px) {
                    padding-top: 10px;
                }
                border-top: 1px solid #F5DF4D;
                .btn {
                    border: 1px solid #F5DF4D;
                    box-sizing: border-box;
                    font-size: rem(16);
                    @media screen and(max-width: 1440px) {
                        font-size: rem(12);
                    }


                    padding: 5px 35px;

                    @media screen and(max-width: 1366px) {
                        padding: 5px 25px;
                    }
                }
            }
        }

        transition: all .3s ease-in;
    }

    &__info {
        color: #AA9710;
        font-size: rem(12);
        transition: all .3s ease-in;
        padding-left: 14px;

        @media screen and (max-width: $tableWidth) {
            padding-left: 6%;
            font-size: rem(15);
        }
    }

    .info-item {
        display: flex;
        align-items: center;
        margin-bottom: .5rem;

        &__title {
            width: 70%;
        }
    }
}

.card-item:hover {
    background: url('../../img/card_active.png') no-repeat center;
    background-size: 99%;

    @media screen and (max-width: $tableWidth) {
        background: none;
    }
}

.card-item__without:hover {
    background: url('../../img/card-more.png') no-repeat center;
    background-size: 100%;

    @media screen and (max-width: $tableWidth) {
        background: none;
    }
}

.card-item.select {
    background: url('../../img/card_active.png') no-repeat center;
    background-size: 99%;
    @media screen and (max-width: $tableWidth) {
        background: none;
    }
}

.hide_interface {
    .card-block {
        height: 75vh;

        @media screen and (max-width: 2660px) {
            height: 85vh;
        }

        @media screen and (max-width: 1920px) {
            height: 75vh;
        }

        @media screen and (max-width: 1440px) {
            height: 73vh;
        }

        @media screen and (max-width: 1280px) {
            height: 55vh;
        }

        @media screen and (max-width: 1024px) {
            height: 52vh;
        }

        .card__wrapper {
            height: 63vh;
            overflow-y: auto;

            // @media screen and (max-width: 2660px) {
            //     height: 58vh; 
            // }

            @media screen and (max-width: 2660px) and (max-height: 2000px) {
                height: 58vh; 
            }
    
            @media screen and (max-width: 2660px) and (max-height: 1460px) {
                height: 64vh;
            }
            
            @media screen and (max-width: 2660px) and (max-height: 1350px) {
                height: 58vh;
            }
    
            @media screen and (max-width: 1920px) {
                height: 55vh;
            }

            @media screen and (max-width: 1920px) and (max-height: 1200px) {
                height: 58vh;
            }
    
            @media screen and (max-width: 1920px) and (max-height: 1050px) {
                height: 55vh;
            }
    
            @media screen and (max-width: 1440px) {
                height: 50vh;
            }
                
            @media screen and (max-width: 1366px) {
                height: 46vh;
            }
        }
    }
}


.rewards_page {
    .card-block {
        @media screen and (max-width: $tableWidth) {
            background: none;
        }
    }
}