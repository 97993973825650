.sidebar-wrapper {
    width: 9%;
    margin-left: auto;

    @media screen and (max-width: $tableWidth) {
        display: none;
    }

    .sidebar_block {
        position: relative;
        padding: 57px 13px;
        top: 0;
        img {
            height: 100%;
        }

        &.hide {
            display: none;
        }

        // @media screen and (max-width: 2660px) {
        //     padding: 50px 13px 80px 13px;
        //     top: -1%;

        //     &.referal_block {
        //         top: -5%;
        //     }
        // }

        @media screen and (max-width: 2660px) and (max-height: 2000px) {
            padding: 50px 20px 80px 20px;
            top: -1%;

            &.referal_block {
                top: -5%;
            }
        }
    
        @media screen and (max-width: 2660px) and (max-height: 1460px) {
            padding: 50px 20px 80px 20px;
            top: -1.1%;

            &.referal_block {
                top: -5%;
            }
        }
        
        @media screen and (max-width: 2660px) and (max-height: 1350px) {
            padding: 50px 20px 80px 20px;
            top: -1.1%;

            &.referal_block {
                top: -5%;
            }
        }

        @media screen and (max-width: 2048px) {
            padding: 19px 13px;
            top: -2%;

            &.referal_block {
                top: -5%;
            }
        }

        @media screen and (max-width: 1920px) {
            padding: 37px 13px;
            top: -2.1%;

            &.referal_block {
                top: -2.7%;
            }
        }

        @media screen and (max-width: 1600px) {
            padding: 37px 8px;
            top: -2.5%;
        }

        @media screen and (max-width: 1536px) {
            padding: 37px 8px;
            top: -3%;
        }

        @media screen and (max-width: 1440px) {
            top: -4.3%;
            padding: 25px 13px;
        }

        @media screen and (max-width: 1366px) {
            top: -5.2%;
            padding: 18px 14px 34px 13px;

            &.referal_block {
                top: -7%;
            }
        }

        @media screen and (max-width: 1024px) {
            top: -3.6%;
            padding: 34px 14px 34px 13px;
        }

        @media screen and (max-width: 1024px) and (max-height: 1366px) {
            top: -1.4%;
            padding: 34px 14px 34px 13px;
        }

        @media screen and (max-width: 1024px) and (max-height: 728px) {
            top: -3.4%;
            padding: 34px 14px 34px 13px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    &.staking_sidebar {
        .earned_block {
            top: 0;

            .information_value {
                justify-content: flex-start;
                align-items: flex-start;
            }
        }

        .referal_block {
            top: 1.5%;
        }

        @media screen and (max-width: 2660px) {
            .earned_block {
                top: -1%;
                padding-top: 37px;
            }
    
            .referal_block {
                top: -5%;
            }
        }

        @media screen and (max-width: 2048px) {
            .earned_block {
                top: .6%;
            }
    
            .referal_block {
                top: 1%;
            }
        }

        @media screen and (max-width: 1920px) {
            .earned_block {
                top: -2%;
            }
    
            .referal_block {
                top: 1%;
            }
        }

        @media screen and (max-width: 1440px) {
            .earned_block {
                top: -4%;
            }
        }

        @media screen and (max-width: 1366px) {
            .earned_block {
                top: -5%;
            }
    
            .referal_block {
                top: 1%;
            }
        }

        @media screen and (max-width: 1024px) and (max-height: 1366px) {
            .earned_block {
                top: -1%;
            }
        }

        @media screen and (max-width: 1024px) and (max-height: 728px) {
            .earned_block {
                top: -3%;
            }
        }
    }

    img {
        width: 100%;
        

        @media screen and (max-width:2660px){
            width: 100%;
        }

        @media screen and (max-width:1280px){
            top: -1.5%;
        }

        @media screen and (max-width:2048px){
            top: -2%;
        }

        @media screen and (max-width:1920px){
            top: -2.6%;
        }

        @media screen and (max-width:1400px){
            top: -4%;
        }
    }

    @media screen and (max-width:2660px){
        width: 14%;
    }

    @media screen and (max-width:2048px){
        width: 11.6%;
    }

    @media screen and (max-width:1920px){
        width: 12.6%;
    }

    @media screen and (max-width:1440px){
        top: -2.4%;
        width: 14.6%;
    }

    @media screen and (max-width:1366px){
        top: -2.4%;
        width: 16%;
    }

    @media screen and (max-width:1280px){
        top: -3%;
        width: 18%;
    }

    @media screen and (max-width:1024px){
        top: -2%;
        width: 21%;
    }
}

.block_title {
    font-weight: 600;
    font-size: rem(16);
    letter-spacing: 0.05em;
    text-shadow: 0px 0px 15px rgba(222, 187, 36, 0.4);
    margin-bottom: 25px;

    @media screen and (max-width: 1440px) {
        margin-bottom: 0;   
    }
}

.block_information {
    .information_item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
        font-size: rem(12);
        padding: 15px 0;
        border-top: 1px solid rgba(245, 223, 77, 0.3);
        border-bottom: 1px solid rgba(245, 223, 77, 0.3);
        position: relative;   

        .information__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-top: 0;
            max-height: 0;
            overflow: hidden;

            transition: all .3s ease-in;

            &.show {
                max-height: 30vh;
                padding-top: 16px;
            }

            .item {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.1vh;

                .value {
                    padding-right: 30px;

                    @media screen and (max-width: 1920px) {
                        padding-right: 20px;
                    }


                    @media screen and (max-width: 1600px) {
                        padding-right: 12px;
                    }
                }
            }
        }
        
        &.slide {
            cursor: pointer;
        }

        &.show {
            .information_value.information__info {
                &::after {
                    transform: translateY(-54%) scale(2) rotate(-180deg);

                    @media screen and (max-width: 1920px) {
                        transform: translateY(-54%) scale(1.2) rotate(-180deg);
                    }
                }
            }
        }

        .information_value {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;

            .postscript {
                font-size: rem(8);
                color: #AA9710;
                opacity: .4;
            }

            &.information__info {
                padding-right: 30px;
                position: relative;

                @media screen and (max-width: 1920px) {
                    padding-right: 20px;
                }
    
                @media screen and (max-width: 1600px) {
                    padding-right: 12px;
                }
    
                &::after {
                    content: url('../../img/icons/arrow.svg');
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-64%) scale(2);

                    @media screen and (max-width: 1920px) {
                        transform: translateY(-54%) scale(1.2);
                    }
                }
            }
        }

        @media screen and (max-width: 2048px) {
            padding: 10px 0;
        }

        @media screen and (max-width: 1920px) {
            padding: 15px 0;
        }

        @media screen and (max-width: 1440px) {
            padding: 8px 0;
        }

        @media screen and (max-width: 1366px) {
            padding: 10px 0;
        }

        .information_title {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: rem(12);
            .info_block {
                z-index: 999;
            }

            .info_popup {
                // opacity: 0;
                display: none;
                position: absolute;
                top: 160%;
                left: 0;
                width: 200px;
                height: 119px;
                background: #000;
                z-index: 9999;
                box-sizing: border-box;
                padding: 12px 12px;
                // font-size: rem(12);
                font-size: 12px;
                color: #F5DF4D;
                line-height: 1.2;
                filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));

                @media screen and (max-width: $tableWidth) {
                    left: 28px;
                }

                &.show {
                    display: block;
                }

                &::before {
                    content: '';
                    position: absolute;
                    border: 32px solid transparent;
                    border-bottom: 23px solid rgb(0, 0, 0);
                    display: block;
                    width: 0;
                    height: 0;
                    top: -46%;
                    left: 0;
                    filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
                    z-index: 9998;
                    mix-blend-mode: overlay;
                }
            }

            .info_block {
                position: absolute;
                top: 0;
                right: -20px;
                width: 15px;
                height: 15px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: #000;
                display: flex;
                box-shadow: 0px 3px 8px 1px rgba(245, 223, 77, 0.2);
            }
        }

        .postscript {
            color: #AA9710;
            font-size: rem(10);
        }

        .info_popup {
            // opacity: 0;
            display: none;
            position: absolute;
            top: 70px;
            left: 0;
            width: 95%;
            // height: 119px;
            background: #000;
            z-index: 9999;
            box-sizing: border-box;
            padding: 12px 12px 46px 12px;
            // font-size: rem(12);
            font-size: 12px;
            line-height: 1.2;
            color: #F5DF4D;
            line-height: 12px;
            filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
            text-align: left;

            @media screen and (max-width: 1920px) {
                top: 60px;
            }

            @media screen and (max-width: $tableWidth) {
                left: 28px;
            }

            &.show {
                display: block;
            }

            &::before {
                content: '';
                position: absolute;
                border: 30px solid transparent;
                border-bottom: 20px solid rgb(0, 0, 0);
                display: block;
                width: 0;
                height: 0;
                top: -50px;
                left: 0;
                filter: drop-shadow(0px 2px 10px rgba(245, 223, 77, 0.3));
                z-index: 9998;
                mix-blend-mode: overlay;
            }
        }
    }
}

.info_block {
    height: 40%;

    @media screen and (max-width: 2560px) {
        height: 60vh;
    }

    @media screen and (max-width: 1920px) {
        height: 60vh;
    }

    @media screen and (max-width: 1536px) {
        height: 55vh;
    }

    @media screen and (max-width: 1440px) {
        height: 50vh;
    }

    @media screen and (max-width: 1366px) {
        height: 52vh;
    }

    @media screen and (max-width: 1024px) and (max-height: 1366px) {
        height: 27vh;
    }

    @media screen and (max-width: 1024px) and (max-height: 728px) {
        height: 50vh;
    }
}

.earned_block {

    height: 12%;
    top: 0;
    position: relative;

    @media screen and (max-width: 2660px) {
        height: 20vh;
        top: 2%;
    }

    @media screen and (max-width: 1920px) {
        height: 20vh;
    }

    @media screen and (max-width: 1600px) {
        height: 20vh;
    }

    @media screen and (max-width: 1536px) {
        height: 22vh;
    }

    @media screen and (max-width: 1440px) {
        height: 24vh;
    }

    @media screen and (max-width: 1366px) {
        height: 24vh;
    }

    @media screen and (max-width: 1024px) and (max-height: 1366px) {
        height: 11vh;
    }

    @media screen and (max-width: 1024px) and (max-height: 728px) {
        height: 23vh;
    }
    
    
    // @media screen and (max-width: 1366px) {
    //     img {
    //         height: 95%;
    //     }
    // }

    .block_title {
        margin-bottom: 10px;
        padding-bottom: 6px;
        border-bottom: 1px solid rgba(245, 223, 77, 0.3);
    }
    .information_item {
        padding: 0;
        border: none;
    }

    .button_block {
        // margin-top: 10%;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 13%;
        left: 50%;
        transform: translateX(-50%);

        .btn {
            padding: 10px 70px;
            font-size: rem(12);

            @media screen and (max-width: 1920px) {
                padding: 6px 40px;
            }
        }
    }

    @media screen and (max-width: 1280px) {
        img {
            height: 98%;
        }
    }
}

.referal_block {
    @media screen and (max-width: 2660px) {
        top: -3%;
    }

    @media screen and (max-width: 1920px) {
        top: -1%;
    }

    @media screen and (max-width: 1366px) {
        top: -10%;
    }

    .block_title {
        margin-bottom: 0;
    }
    .block_subtitle {
        color: #AA9710;
        font-size: rem(10px);
    }
    .block_link {
        position: relative;
        z-index: 69;
        margin: 19px 0;
        font-size: rem(10);
        display: flex;
        align-items: center;

        button {
            cursor: pointer;
            background: none;
            border: none;
        }
        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 62%;
            display: block;
            margin-right: 10px;
        }
    }
    .button_block {
        font-size: rem(10);

        .btn {
            padding: 2px 25px;
            font-size: rem(12);
        }
    }

    @media screen and (max-width: 1280px) {
        img {
            height: 90%;
        }
    }
}

.menu_hide {
    .sidebar-wrapper .sidebar_block {

        @media screen and (max-width: 2660px) {
            top: 0%;
        }

        @media screen and (max-width: 1920px) {
            top: 0%;
        }
    }
}

.logout_block {
    margin-bottom: 15px;
    .header_wax__wrapper {

    position: relative;
    z-index: 69;
        margin-top: 10px;
        .header_wax {
            font-size: rem(12);
        }

        svg {
            width: 30px;
            height: 30px;
        }
    }

    .logout_btn {
        font-size: rem(12);

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.rewards-info_block {
    img {
        height: 100%;
    }
    .block_information {

        .information_item {
            &.indent {
                border-top: none;
                margin-top: 80px;

                @media screen and (max-width: 1440px) {
                    margin-top: 30px;
                }

                @media screen and (max-width: 1366px) {
                    margin-top: 20px;
                }
            }
        }
    }

    .button_block {
        display: flex;
        justify-content: center;
        align-self: center;
        margin-top: 36px;

        @media screen and (max-width: 1440px) {
            margin-top: 20px;
        }

        @media screen and (max-width: 1366px) {
            margin-top: 15px;
        }
        .btn {
            padding: 6px 27px;
            font-size: rem(12);
        }
    }
}