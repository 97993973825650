.page_caption {
    color: #F5DF4D;
    font-size: rem(24);
    font-weight: 600;
    text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
    text-align: center;
    margin-right: 9rem;
    margin-top: -0.5rem;
    letter-spacing: 3px;

    @media screen and (max-width: 1920px) {
        margin-top: -3.3rem;
        margin-right: 16rem;
    }

    @media screen and (max-width: 1440px) {
        margin-top: -3.3rem;
        margin-right: 22rem;
    }

    @media screen and (max-width: 1380px) {
        margin-top: -3.3rem;
        margin-right: 16rem;
    }

    @media screen and (max-width: $tableWidth ) {
        margin: 0;
        display: none;
    }
}

.page_caption__auth {
    margin-right: -4rem;

    @media screen and (max-width: 2560px) {
        margin-right: -1rem;
        margin-top: -1.4rem;
        padding-bottom: .3rem;
    }

    @media screen and (max-width: 1920px) {
        margin-right: -1rem;
        margin-top: -4rem;
        padding-bottom: .2rem;
    }

    @media screen and (max-width: 1460px) {
        margin-right: 7rem;
        margin-top: -5rem;
        padding-bottom: 1rem;
    }

    @media screen and (max-width: 1366px) {
        margin-right: 0rem;
    }

    @media screen and (max-width: $tableWidth) {
        padding-top: 17px;
    }

    @media screen and (max-width: 320px) {
        padding-top: 17px;
    }
}

.mobile_caption {
    display: none;
    padding: 17px 16px 0 16px;

    &.center {
        justify-content: center;
    }

    h1 {
        font-size: rem(26);
        font-weight: 800;
        letter-spacing: 0.14em;
        text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
    }

    .btn {
        font-size: rem(14);
        letter-spacing: 0.05em;
        padding: 6px 29px;
        filter: drop-shadow(0px 4px 10px rgba(27, 23, 1, 0.5));
    }

    @media screen and (max-width: $tableWidth) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 320px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}