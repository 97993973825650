.header {
    position: relative;
    height: 0;
    overflow: hidden;
    opacity: 0;
    width: 100%;
    transition: all .4s ease-in;
    z-index: 999;

    @media screen and (max-width: $tableWidth ) {
        @include blur-block;
        height: auto;
        overflow: unset;
    }

    &.show {
        height: 120px;
        opacity: 1;

        @media screen and (max-width: $tableWidth ) {
            height: 76px;
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media screen and (max-width: $tableWidth ) {
            display: none;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 69;
        padding: 15px 50px;

        @media screen and (max-width: $tableWidth ) {
            padding: 20px 12px;
            height: 76px;
        }
    }
}

.header_logo {
    &__img {
        width: 50px;
        height: 50px;
        fill: $mainFontColor;

        @media screen and (max-width: $tableWidth ) {
            width: 26px;
            height: 34px;
        }
    }
    
    &__btn {
        background: none;
        border: none;
        cursor: pointer;
    }
}

.header_wax__wrapper {
    .header_wax {
        font-size: rem(12);
        cursor: pointer;
        @media screen and (max-width: $tableWidth ) {
            font-size: rem(14);

            .price {
                display: none;
            }
        }

        a {
            color: $mainFontColor;
        }

        .wax-logo {
            width: 52px;
            height: 52px;
            &.auth {
                width: 25px;
                height: 25px;

                @media screen and (max-width: 2560px ) {
                    width: 35px;
                    height: 35px;
                }

                @media screen and (max-width: 1920px ) {
                    width: 25px;
                    height: 25px;
                }

            }
        }

        .wax-logo {
            margin-right: 10px;

            @media screen and (max-width: $tableWidth ) {
                width: 24px;
                height: 34px;
            }
        }

        img {
            width: 42px;
            height: 42px;
            margin-right: 10px;

            @media screen and (max-width: $tableWidth ) {
                width: 24px;
                height: 34px;
                margin-right: 7px;
            }
        }

        .logout_btn {
            background: none;
            border: none;
            color: #F5DF4D;
            padding-left: 0;

            transition: all .2s;
            @media screen and (max-width: $tableWidth) {
                padding: 0;
            }

            svg {
                margin-right: 2px;
                margin-bottom: 3px;
                margin-left: 5px;

                width: 15px;
                height: 16.5px;

                @media screen and (max-width: 2560px) {
                    height: 19.5px;
                }

                @media screen and (max-width: 1920px) {
                    height: 16.5px;
                }

                @media screen and (max-width: $tableWidth) {
                    width: 15px;
                    height: 12.5px;
                    margin-right: 0;
                }
            }

            &:hover {
                color: $mainFontColor;
            }
        }
    }
}

.second_header {
    display: none; 

    &.show {
        display: block;
        padding: 38px 54px;
    }

    .header_logo {
        
        &__btn {
            background: none;
            border: none;
            cursor: pointer;
            position: relative;
            z-index: 69;
        }
    }
}