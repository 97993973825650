@import './Utils/fonts';
@import './Utils/vars';
@import './Utils/mixins';
@import './Utils/reset';

html {
    line-height: $mainFontHeight;
    color: $mainFontColor;
    font-family: $mainFont;
    font-size: 40px;
    background-color: #000;

    @media screen and (max-width: 3840px) {
        font-size: 30px;
    }

    @media screen and (max-width: 2048px) {
        font-size: 24px;
    }

    @media screen and (max-width: 1920px) {
        font-size: 18px;
    }

    @media screen and (max-width: 1366px) {
        font-size: 16px;
    }

    @media screen and (max-width: 1260px) {
        font-size: 12px;
    }

    @media screen and (max-width: $tableWidth) {
        font-size: 16px;
    }

    @media screen and (max-width: $smPhoneWidth) {
        font-size: 14px;
    }
}

html, body, #root, .app_wrapper {
    @media screen and (max-width: $tableWidth) {
        height: 100%;
    }
}

a {
    color: $linkColor;
    transition: color .3s ease-in;

    &:hover {
        color: $hoverLinkColor;
    }
}

button {
    cursor: pointer;
}

.btn{
    position: relative;
    transform: skew(15deg);
    background: none;
    border: 1px solid $borderButton;
    color: $mainFontColor;
    font-size: rem(14);
    padding: 10px 30px;
    display: inline-block;

    transition: all .2s ease-in;
    &::before{
        content:'';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        height: 4px;
        box-shadow: 0px 0px 14px 6px rgba(245, 223, 77, 0.2);
        transform: translate(-50%,-50%) skew(-15deg);
        background-color: rgba(245, 223, 77, 0.1);
        visibility: hidden;
        opacity: 0;
        transition: .3s ease all;
        border-radius: 30px;
    }
    &__wrap{
        display: block;
        transform: skew(-15deg); 
    }

    &:hover {
        &::before{
            visibility: visible;
            opacity: 1;
        }
    }

    &.active {
        &::before{
            visibility: visible;
            opacity: 1;
        }
    }

    &.opacity {
        opacity: .7;
        cursor: default;

        &::before{
            visibility: hidden;
            opacity: 0;
        }
    }

    &.paint {
        background: rgba(245, 223, 77, 0.1);
        border: 1px solid #968E53;
        font-size: rem(16);
        font-weight: 600;
        letter-spacing: 0.14em;
        text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
        padding: 12px 32px;
    }

    &.hideStroke {
        border: none;
        font-size: rem(16);
        font-weight: 600;
        letter-spacing: 0.14em;
        text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
        transition: all .3s ease-in;
        background: unset;
        padding: 12px 32px;
        &:hover {
            background: rgba(245, 223, 77, 0.1);
            border: 1px solid #968E53;
            
        }

        &::before{
            visibility: hidden;
            opacity: 0;
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar:horizontal {
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $scrollTrackColor;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $scrollThumbColor;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #E1C54E;
}

.marked_text {
    color: $mainFontColor;
    font-weight: 600;
    font-size: rem(13);

    @media screen and (max-width: $tableWidth) {
        font-size: rem(18);
    }

    @media screen and (max-width: $smPhoneWidth) {
        font-size: rem(18);
    }
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

// Pages
@import './Containers/App';
@import './Containers/MainPage';
@import './Containers/Information';
@import './Containers/HomeShelterPage';
@import './Containers/StatsPage';

// Components
@import './Components/header';
@import './Components/menu';
@import './Components/alert';
@import './Components/loginBtn';
@import './Components/caption';
@import './Components/btnBlock';
@import './Components/information';
@import './Components/modal';
@import './Components/showBtn';
@import './Components/sidebar';
@import './Components/statusBar';
@import './Components/cardBlock';
@import './Components/statsInformation';
@import './Components/transactionTable';
@import './Components/common';
@import './Components/stakingModal';
@import './Components/rewardsModal';