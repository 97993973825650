.app_wrapper {
    max-height: 100vh;
    overflow: hidden;
    background-image: url('../../../assets/img/bunker_main.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    transition: all .4s ease-in;
    @media screen and (max-width: $tableWidth) {
        max-height: unset;
    }
    &.auth {
        background-image: url('../../../assets/img/room.jpg');

        @media screen and (max-width: $tableWidth) {
            &.shelter {
                background-position: -150px;

                &.shelter__scroll {
                    background-position: -400px;
                }
            }
            
        }
    }

    &.success {
        background-image: url('../../img/bunker_success.png');
    }

    &.error {
        background-image: url('../../img/bunker_error.jpg');
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .4);
    }

    &.shadow::before {
        display: block;
        z-index: 1000;
    }

    &__container {
        width: 99%;
        margin: 8px auto;
        overflow: hidden;

        @media screen and (max-width: $tableWidth ) {
            overflow: hidden;
            width: 100%;
            height: 100vh;
            margin: 0;

            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &__container.blur {
        backdrop-filter: blur(8px);
    }
}

.main_wrapper {
    display: flex;
    width: 100%;
    height: calc(100vh - 120px);

    @media screen and (max-width: $tableWidth ) {
        height: calc(100% - 146px);
        display: flex;
        flex-direction: column;
        // flex-grow: 1;
    }

    &.menu_hide {
        height: 100vh;
    }
}

.content {
    position: relative;
    width: 91.8%;

    &__bg {
        position: absolute;
        top: -40px;
        left: 0;
        width: 100%;
        height: 100%; 
    }

    &__container {
        position: relative;
        z-index: 69;
        width: 100%;
        height: 100%;
        padding-left: 45px;
        padding-right: 20px;
        &.not_auth {
            padding-left: 70px;
            @media screen and (max-width: $tableWidth ) {
                padding: 0;
            }
        }
        
        @media screen and (max-width: 1920px ) {
            padding: 32px;
        }

        @media screen and (max-width: $tableWidth ) {
            padding: 0;
        }
    }

    @media screen and (max-width:2660px){
        width: 87.3%;
        &__bg {
            top: -33px;
            left: 1%;
        }
    }

    @media screen and (max-width:2048px){
        width: 91.3%;
        &__bg {
            top: -63px;
            left: 0.2%;
            height: 103%;
        }
    }

    @media screen and (max-width:1920px){
        width: 90%;
        &__bg {
            top: -6.3%;
            height: 102%;
            left: .3%;
        }
    }

    @media screen and (max-width: 1536px ) {
        width: 90%;
        &__bg {
            top: -8.3%;
            height: 131%;
            left: .3%;
        }
    }

    @media screen and (max-width:1440px){
        width: 88.4%;
        &__bg {
            top: -10.5%;
            height: 108%;
            left: .3%;
        }
    }

    @media screen and (max-width:1366px){
        width: 88%;
        &__bg {
            top: -12%;
            height: 130%;
            left: .3%;
        }
    }

    @media screen and (max-width:1280px){
        width: 87.1%;
        &__bg {
            top: -9%;
            height: 106%;
            left: 0.4%;
        }
    }

    @media screen and (max-width:1024px){
        width: 83.7%;
        &__bg {
            top: -9%;
            height: 106%;
            left: 0.4%;
        }
    }

    @media screen and (max-width: 1024px) and (max-height: 1366px) {
        width: 83.7%;
        &__bg {
            top: -4%;
            height: 53%;
            left: 0.4%;
        }
    }

    @media screen and (max-width: 1024px) and (max-height: 900px) {
        width: 83.7%;
        &__bg {
            top: -9%;
            height: 106%;
            left: 0.4%;
        }
    }

    @media screen and (max-width: $tableWidth ) {
        width: 100%;
        height: 100%;
        padding: 5px 0;
        &__bg {
            display: none;
        }
    }
}


.content.hide_interface {
    width: 100%;

    .content__bg {
        top: 0;
        height: 98%;
        left: 0;
    }
}

.main_wrapper__sidebar{

    .content{
        width: calc(100% - 17%);
        &__bg{
            top: -1.9%;
            left: .7%;
        }
    }
    @media screen and (max-width:3840px){
        
        .content{
            width: calc(100% - 17.5%);
            &__bg{
                top: -45px;
                left: .5%;
            }
        }
    }

    @media screen and (max-width:2660px){
        
        .content{
            width: calc(100% - 26.5%);
            &__bg{
                top: -4%;
                left: 0;
            }
        }
    }


    @media screen and (max-width:2048px){
        
        .content{
            width: calc(100% - 20.7%);
            height: 103%;
            &__bg{
                top: -4.4%;
                left: 0;
                height: 99%;
            }
        }
    }

    @media screen and (max-width:1920px){
        
        .content{
            width: calc(100% - 22.7%);
            height: 100%;
            &__bg{
                top: -6.1%;
                left: 0;
                height: 100%;
            }
        }
    }

    @media screen and (max-width: 1536px ) {
        .content{
            width: calc(100% - 22.7%);
            height: 100%;
            &__bg{
                top: -8.1%;
                left: 0;
                height: 129%;
            }
        }
    }

    @media screen and (max-width:1440px){
        .content{
            width: calc(100% - 27%);
            
            &__bg{
                top: -10.5%;
                left: 0;
                height: 108.3%;
            }
        }
    }

    @media screen and (max-width:1366px){
        .content{
            width: calc(100% - 29%);
            
            &__bg{
                top: -12%;
                left: 0;
                height: 130%;
            }
        }
    }

    @media screen and (max-width:1280px){
        
        .content{
            width: calc(100% - 31.1%);
            &__bg{
                top: -9.4%;
                left: 0;
                height: 106.6%;
            }
        }
    }

    @media screen and (max-width: 1024px) and (max-height: 1366px) {
        .content{
            width: calc(100% - 38.1%);
            &__bg{
                top: -4%;
                left: 0;
                height: 53%;
            }
        }
    }

    @media screen and (max-width: 1024px) and (max-height: 900px) {
        .content{
            width: calc(100% - 38.1%);
            &__bg{
                top: -9%;
                left: 0;
                height: 105.6%;
            }
        }
    }



    @media screen and (max-width: $tableWidth){
        .content{
            width: 100%;
            height: 100%;
        }
    }
}


.main_wrapper__sidebar {
    .hide_interface {
        width: calc(100% - 9.7%);
        &__bg{
            top: -1.9%;
            left: .7%;
        } 

        @media screen and (max-width: 2660px) {
            width: calc(100% - 14.7%);
        }

        @media screen and (max-width: 1920px) {
            width: calc(100% - 13.7%);
        }

        @media screen and (max-width: 1440px) {
            width: calc(100% - 15.5%);
        }

        @media screen and (max-width: 1366px) {
            width: calc(100% - 16.5%);
        }

        @media screen and (max-width: 1280px) {
            width: calc(100% - 18.5%);
        }

        @media screen and (max-width: 1024px) {
            width: calc(100% - 21.5%);
        }

    }
}