.transaction_block {
    width: 100%;
    color: $faqFontColor;
    font-size: rem(12);
    line-height: 14px;
    height: 63vh;
    overflow-y: auto;
    // margin-top: 63px;

    // @media screen and (max-width: 2560px) {
    //     height: 58vh;
    // }

    @media screen and (max-width: 2660px) and (max-height: 2000px) {
        height: 58vh; 
    }

    @media screen and (max-width: 2660px) and (max-height: 1460px) {
        height: 62vh;
    }
    
    @media screen and (max-width: 2660px) and (max-height: 1350px) {
        height: 58vh;
    }

    @media screen and (max-width: 1536px) {
        height: 51vh;
    }

    @media screen and (max-width: 1440px) {
        height: 51vh;
    }

    @media screen and (max-width: 1366px) {
        height: 48vh;
    }

    @media screen and (max-width: 1024px) and (max-height: 1366px) {
        height: 30vh;
    }

    @media screen and (max-width: 1024px) and (max-height: 900px) {
        height: 48vh;
    }

    @media screen and (max-width: $tableWidth) {
        margin-top: 8px;
        height: 52vh;
    }

    @media screen and (max-width: 395px) and (max-height: 720px){
        height: 48vh;
    }

    @media screen and (max-width: 375px) and (max-height: 820px){
        height: 54vh;
    }

    @media screen and (max-width: 375px) and (max-height: 670px){
        height: 47vh;
    }

    @media screen and (max-width: 375px) and (max-height: 620px) {
        height: 44vh;
    }

    @media screen and (max-width: 320px) and (max-height: 600px){
        height: 42vh;
    }


    @media screen and (max-width: $tableWidth) and (max-height: 553px) {
        height: 38vh;
    }

    @media screen and (max-width: 375px) and (max-height: 555px) {
        height: 36vh;
    }

    &__wrapper {
        height: max-content;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
    }
}

.transaction_table {
    width: 98%;
    // font-size: rem(16);
    color: $mainFontColor;

    .table_id {
        width: 20px;

        @media screen and (max-width: $tableWidth) {
            width: 10px;
        }
    }

    @media screen and (max-width: $tableWidth) {
        color: #968E53;
        white-space: nowrap;
    }
    &__header {
        background: rgba(97, 157, 183, 0.2);
        backdrop-filter: blur(3px);
        box-sizing: border-box;
        font-size: rem(18);

        @media screen and (max-width: $tableWidth) {
            font-weight: 600;
            font-size: 16px;
        }

        th {
            padding: 40px 0 40px 19px;

            @media screen and (max-width: 1920px) {
                padding: 30px 0 30px 19px;
            }

            @media screen and (max-width: $tableWidth) {
                padding: 15px 0 15px 19px;
            }

            width: 132px;
        }
    }

    &__line {
        font-size: rem(16);
        td {
            padding: 40px 19px;
            border-bottom: 1px solid #4E4506;

            @media screen and (max-width: 1920px) {
                padding: 20px 19px;
            }

            @media screen and (max-width: $tableWidth) {
                padding-right: 132px;
            }

            &.table_id {
                padding-right: 20px;
            }

            &.bloks-history{
                cursor: pointer;
            }
        }

        @media screen and (max-width: $tableWidth) {
            margin-left: 16px;
            font-weight: 400;
            font-size: 16px;
        }

    }
}

.transaction__title {
    display: block;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.095em;
    color: #F5DF4D;
    text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
    margin-left: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-top: 60px;

    @media screen and (max-width: 1920px) {
        margin-top: 50px;
    }

    @media screen and (max-width: 1440px) {
        margin-top: 37px;
    }

    @media screen and (max-width: $tableWidth) {
        display: block;
        margin-top: 10px;
        margin-bottom: 8px;
    }

}