.stats_page {
    // backdrop-filter: blur(6px);
    padding: 2px 30px;

    @media screen and (max-width: $tableWidth) {
        padding: 2px 0;
    }

    .success {
        color: #05D132;
    }

    .error {
        color: #FF0000;
    }

    .progress {
        color: #0057FF;
    }
}