.show-btn {
    position: absolute;
    transition: all .2s ease-in;
    cursor: pointer;


    width: 31vw;
    transform: translate(-50%, 0);
    top: unset;
    bottom: 20px;
    left: 35%;
    height: max-content;

    img {
        height: auto;
        width: 100%;
    }

    &:hover {
        transform: translate(-50%, 0) scale(1.1);
    }

    @media screen and (max-width: 2660px) {
        width: 26vw;
        left: 31%;
    }

    @media screen and (max-width: 1920px) {
        width: 25vw;
        left: 30.8%;
        bottom: 0;
    }

    @media screen and (max-width: 1680px) {
        left: 31%;
    }

    @media screen and (max-width: 1600px) {
        left: 31%;
    }

    @media screen and (max-width: 1536px) {
        width: 22vw;
        left: 31%;
        top: 21%;
    }

    @media screen and (max-width: 1440px) {
        width: 25vw;
        left: 29%;
        bottom: -30px;
    }

    @media screen and (max-width: 1366px) {
        width: 21vw;
        left: 29%;
    }


    @media screen and (max-width: 1024px) {
        width: 28vw;
        left: 27%;
    }

    @media screen and (max-width: 1024px) and (max-height: 1366px) {
        width: 60vw;
        left: 27%;
    }

    @media screen and (max-width: 1024px) and (max-height: 900px) {
        width: 28vw;
        left: 27%;
    }

    @media screen and (max-width: $tableWidth) {
        top: calc(50% - 100px);
        left: calc(50% - 23rem);
        width: 350px;
    }

    &.rewards {

        &.big_element {
            visibility: visible;
        }

        &.phone_element {
            visibility: hidden;
        }

        @media screen and (max-width: $tableWidth) {
            &.big_element {
                visibility: hidden;
            }
    
            &.phone_element {
                visibility: visible;
            }
        }

        height: auto;
        width: 16.2vw;
        top: unset;
        bottom: 12.5vh;
        transform: translate(-50%, 0);
        left: calc(30% + 31vw);

        &:hover {
            transform: translate(-50%, 0) scale(1.1);
        }

        @media screen and (max-width: 2660px) {
            width: 15vw;
            left: calc(23% + 31vw);
        }

        @media screen and (max-width: 1920px) {
            width: 14vw;
            left: calc(23% + 31vw);
        }

        @media screen and (max-width: 1440px) {
            left: calc(20% + 31vw);
        }

        @media screen and (max-width: 1366px) {
            width: 14vw;
            left: calc(20% + 29vw);
            bottom: 2vh;
        }

        @media screen and (max-width: 1024px) {
            width: 15vw;
            left: calc(20% + 30vw);
        }

        img {
            width: 100%;
            height: auto;
        }

        @media screen and (max-width: $tableWidth) {
            width: 100px;
            left: calc(50% - -9rem);
        }
    }

    .label {
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-46%);
        color: #FFFAD8;
        font-weight: 600;
        font-size: rem(20);
        line-height: 24px;
        text-shadow: 0px 4px 20px #000000;
        white-space: nowrap;
    }

}

.main-screen__hide-int {
    .show-btn {
        position: absolute;
        transition: all .2s ease-in;
        cursor: pointer;
    
    
        width: 31vw;
        transform: translate(-50%, 0);
        top: 25%;
        bottom: 20px;
        left: 37.6%;
        height: max-content;

        img {
            height: auto;
            width: 100%;
        }

        @media screen and (max-width: 2660px) {
            width: 26vw;
            left: 38%;
            top: 24%;
        }

        @media screen and (max-width: 2048px) {
            width: 26vw;
            left: 38%;
            top: 30%;
        }
    
        @media screen and (max-width: 1920px) {
            width: 24vw;
            left: 38%;
            top: 21%;
        }

    
        @media screen and (max-width: 1440px) {
            left: 38%;
            top: 9%;
        }
    
        @media screen and (max-width: 1366px) {
            width: 19vw;
            left: 38%;
            top: 14%;
        }
    
        @media screen and (max-width: 1024px) {
            width: 28vw;
            left: 38%;
            top: 14%;
        }

        &.rewards {
            height: auto;
            width: 16.2vw;
            top: unset;
            bottom: 12.5vh;
            transform: translate(-50%, 0);
            left: calc(30% + 31vw);
            
            @media screen and (max-width: 2660px) {
                width: 12vw;
                left: calc(23% + 34vw);
            }

            @media screen and (max-width: 2048px) {
                width: 12vw;
                left: calc(23% + 36vw);
                top: 40%;
            }
    
            @media screen and (max-width: 1920px) {
                left: calc(23% + 34vw);
                top: 33%;
            }
    
            @media screen and (max-width: 1440px) {
                left: calc(20% + 38vw);
                top: 18%;
            }
    
            @media screen and (max-width: 1366px) {
                width: 10vw;
                left: calc(20% + 34vw);
            }
    
            @media screen and (max-width: 1024px) {
                width: 12vw;
                left: calc(20% + 38vw);
                top: 25%;
            }
    
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}