@mixin font-face(
  $font-family,
  $file-path,
  $weight: normal,
  $style: normal,
  $asset-pipeline: false
) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    src: url("#{$file-path}.woff") format("woff"),
      url("#{$file-path}.woff2") format("woff2");
  }
}

@function rem($pixels, $context: $mainFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

@mixin blur-block {
  width: 100%;
  background: $mainBackColor;
  border: .5ps solid $mainBorderColor;
  backdrop-filter: blur(6px);
  box-sizing: border-box;
}