.status-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 22px;
    margin-top: 37px;
    margin: 15px auto 0 auto;
    position: relative;

    // &.rewards {
        padding-left: 4%;
    // }

    @media screen and (max-width: 2660px) {
        margin: 0 auto 0 auto;
        padding-top: 22px;
    }

    @media screen and (max-width: 1920px) {
        padding-top: 12px;
    }

    @media screen and (max-width: 1440px) {
        padding-top: 18px;
    }

    @media screen and (max-width: $tableWidth) {
        display: none;
    }

    .img__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        padding-left: 0.4vw;

        @media screen and (max-width: 2660px) {
            padding-left: 0.6vw;
        }

        @media screen and (max-width: 2048px) {
            padding-left: 0.7vw;
        }

        @media screen and (max-width: 1920px) {
            padding-left: 0.8vw;
        }

        @media screen and (max-width: $tableWidth) {
            display: none;
        }

        img {
            width: 100%;
            height: 4vh;

            @media screen and (max-width: 2660px) {
                height: 8vh;
            }

            @media screen and (max-width: 2048px) {
                height: 6vh;
            }

            @media screen and (max-width: 1920px) {
                height: 7vh;
            }

            @media screen and (max-width: 1536px) {
                height: 10vh;
            }

            @media screen and (max-width: 1440px) {
                height: 10vh;
            }

            @media screen and (max-width: 1024px) and (max-height: 1366px) {
                height: 4vh;
            }
        
            @media screen and (max-width: 1024px) and (max-height: 900px) {
                height: 10vh;
            }
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);

        .btn {
            font-size: rem(16);
            letter-spacing: .14em;
            text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
            font-weight: 600;
            padding: 12px 32px;
            margin-right: 10px;
            box-sizing: border-box;

            @media screen and (max-width: 1440px) {
                padding: 12px 20px;
            }

            @media screen and (max-width: 1440px) {
                padding: 8px 20px;
            }
        }

        @media screen and (max-width: $tableWidth) {
            width: 100%;
            justify-content: space-around;
        }

        .title {
            color: #AA9710;
            font-size: rem(12);
            text-shadow: none;
            font-weight: 400;
            line-height: 14px;
        }

        color: $mainFontColor;
        font-weight: 600;

        .status_item {
            padding-right: 40px;
            padding-left: 40px;
            border-right: 1px solid $mainFontColor;

            @media screen and (max-width: 1920px) {
                padding-right: 20px;
                padding-left: 20px;
            }

            &:last-child {
                border: none;
            }
            @media screen and (max-width: $tableWidth) {
                width: 27%;
                font-weight: normal;
            }
        }
    }
}

.status-bar__mobile {
    display: none;
    @media screen and (max-width: $tableWidth) {
        display: block;
    }
    width: 100%;
    background: rgba(97, 157, 183, 0.1);

    &__container {
       .btn {
           font-size: rem(12);
           letter-spacing: .14em;
           font-weight: 600;
           text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
           padding: 10px 30px;
       }   
    }

    &.nft {
        margin-top: 18px;
        .status-bar__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 9px 0;
        }

        .left__block, .right__block {
            width: 50%;
        }

        .left__block {
            border-right: 1px solid $mainFontColor;
        }

        .status_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-right: none;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .title {
            font-size: rem(14);
            line-height: 14px;
            color: #AA9710;
        }

        .value {
            font-weight: 600;
            font-size: rem(14);
            line-height: 14px;
            color: #F5DF4D;
            text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
        }
    }

    &.rewards {
        margin-top: 14px;
        border-top: 1px solid rgba(245, 223, 77, 0.2);
        border-bottom: 1px solid rgba(245, 223, 77, 0.2);
        background: none;

        .status-bar__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
        }


        .status_item {
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40%;
        }

        .title {
            font-size: rem(14);
            line-height: 14px;
            color: #AA9710;
        }

        .value {
            font-weight: 600;
            font-size: rem(14);
            line-height: 14px;
            color: #F5DF4D;
            text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);
        }
    }
}