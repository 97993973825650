.btn_block {
    width: 100%;
    // height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    
    @media screen and (max-width: $tableWidth) {
        height: auto;
    }

    .back_btn {
        background: none;
        border: none;

        color: $mainFontColor;
        display: flex;
        align-items: center;
        font-weight: 400;

        font-size: rem(14);

        @media screen and (max-width: $tableWidth) {
            font-size: 14px;
        }

        @media screen and (max-width: $smPhoneWidth) {
            font-size: 14px;
        }

        svg {
            width: 12px;
            height: 12px;
        }

        &__img {
            margin-right: 5px;

            @media screen and (max-width: $tableWidth) {
                margin-bottom: 3px;
            }
        }
    }

    .information_btn {
        padding-right: 5px;
        .btn {
            border: 1px solid #AA9710;
            box-sizing: border-box;
            font-size: rem(13);
            padding: 3px 12px;
            cursor: pointer;
        }
        .btn:first-child {
            margin-right: 10px;
        }

        @media screen and (max-width: $tableWidth) {
            display: none;
        }
    }
}

.btn_block__scroll {

    .page_type {
        font-size: rem(20);
        font-weight: 600;
        letter-spacing: 0.14em;
        color: $mainFontColor;
        text-shadow: 0px 4px 15px rgba(222, 187, 36, 0.4);

        .mobile_page_btn {
            width: 100%;
            display: none;
            justify-content: space-between;
            align-items: center;
            padding: 16px 14px;

            .btn:last-child {
                margin-right: 0;
            }

            @media screen and (max-width: $tableWidth){
                display: flex;
            }

            
        }

        .filters__container {
            // @media screen and (max-width: $tableWidth) {
            //     display: none;
            // }
            .filter_block {
                display: flex;
                align-items: center;

                .selector__toggle{
                    position: relative;
                    cursor: pointer;
                    font-size: rem(12);
                    font-weight: 400;
                    line-height: 14px;
                    color: $mainFontColor;
                    letter-spacing: 0;
                    margin-right: 20px;

                    @media screen and (max-width: $tableWidth) {
                        margin-right: 12px;
                    }

                    &:last-child {
                        margin-right: unset;
                    }
            
                    &.open{
                        .selected_value{
                            border-bottom-color: transparent;
                            &::after{
                                transform: translateY(-30% ) rotate(180deg) scale(2.4);
                                
                                @media screen and (max-width: 1920px){
                                    transform: translateY(-50% ) rotate(180deg) scale(1.4);
                                }
                            }
                            span{
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                    .selected_value {
                        font-size: rem(12);
                        padding: 10px 8px 8px 16px;
                        border: 1px solid #968E53;
                        cursor: pointer;
                        width: 250px;
            
                        @media screen and (max-width: 1920px) {
                            width: 150px;
                        }
            
                        @media screen and (max-width: $tableWidth) {
                            width: 120px;
                            padding: 5px 8px 3px 16px;
                        }
            
                        &::after {
                            content: url('../../img/icons/arrow.svg');
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: translateY(-90%) scale(2.4);
                            z-index: 100;      
                            
                            @media screen and (max-width: 1920px){
                                right: 10px;
                                transform: translateY(-50%) scale(1.4);
                            }
                        }            
                    }
                    .selector__list{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        background-color: #000;
            
                        visibility: hidden;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 250px;
                        @media screen and (max-width: 1920px) {
                            width: 150px;
                        }
                        @media screen and (max-width: $tableWidth) {
                            width: 120px;
                        }
                        transition: all .1s ease-in-out;
                        // padding: 50px 16px 15px 16px;
                        padding: 28px 0 15px 0;
                        border: 1px solid #968E53;
                        z-index: 99;
            
                        .item {
                            // transition: all .2s ease-in;
                            padding: 6px 15px;
                            cursor: pointer;
                            font-size: rem(12);
                        }
            
                        .item:hover {
                            background-color: #968E53;
                        }
            
                        &.show {
                            visibility: visible;
                        }
                    }
                }
            }
        }

        .btn {
            margin-right: 20px;
            @media screen and (max-width: $tableWidth) {
                font-size: 12px;
                letter-spacing: 0.14em;
                padding: 10px 20px;
            }

            @media screen and (max-width: $smPhoneWidth) {
                font-size: 12px;
                letter-spacing: 0.14em;
                padding: 10px 10px;
                margin-right: 0;
            }
        }
    }
}

.btn_block__is_auth {
    padding: 0 0px 0px 30px;
    margin-bottom: 60px;
    width: 97%;
    @media screen and (max-width: 1440px) {
        margin-bottom: 30px;
    }
    .btn_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #968E53;
        padding-bottom: 20px;
        padding-top: 4%;

        @media screen and (max-width: $tableWidth) {
            flex-wrap: wrap;
            padding: 0;
            border-bottom: unset;
        }
    }

    &.rewards {
        @media screen and (max-width: $tableWidth) {
            &.big_element {
                display: none;
            }
    
            &.phone_element {
                display: block;
            }
        }
        .btn_container {
            @media screen and (max-width: $tableWidth) {
                border-bottom: none;
            }
        }

        .page_type {
            @media screen and (max-width: $tableWidth) {
                border-bottom: none;
                padding-bottom: 0;

                // .btn {
                //     margin-right: 0;
                //     padding: 10px 10px;
                //     font-size: 11px;
                // }
            }
        }
    }
    .page_type {
        font-size: rem(20);
        font-weight: 600;
        letter-spacing: 0.14em;


        @media screen and (max-width: $tableWidth) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;

            .filters__container {
                width: 100%;
                padding: 16px 14px;
                border-top: 1px solid #968E53;
                border-bottom: 1px solid #968E53;
    
            }
        }
    }

    @media screen and (max-width: $tableWidth) {
        padding: unset;
        margin-bottom: 10px;
        margin-top: 0;
        width: 100%;
    }

    .information_btn {
        @media screen and (max-width: $tableWidth) {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 16px 14px;

            .btn {
                padding: 6px 13px;
                font-size: rem(14);
                color: #F5DF4D;
                border: 0.5px solid #AA9710;
            }
        }
    }
}