.login-btn {
    position: absolute;

    top: calc(50% + 130px);
    left: calc(50% - 168px);
    transform: translate(-50%, -50%);

    width: 500px;
    height: 500px;

    svg {
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
    }

    @media screen and (max-width: 3840px) {
        top: calc(50% + 132px);
        left: calc(50% - 174px);
    }

    @media screen and (max-width: 2660px) {
        top: calc(50% + 50px);
        left: calc(50% - 165px);
        width: 300px;
        height: 300px;
    }

    @media screen and (max-width: 2048px) {
        top: calc(50% + 57px);
        left: calc(50% - 116px);
    }

    @media screen and (max-width: 1920px) {
        top: 53%;
        left: 43.2%;
        width: 250px;
        height: 250px;

    }

    @media screen and (max-width: 1680px ) {
        top: 51%;
        left: 43%;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 1600px ) {
        top: 51%;
        left: 43%;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 1536px ) {
        top: 51%;
        left: 43%;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 1440px) {
        top: calc(50% - 1px);
        left: 41.6%;
        width: 200px;  
        height: 200px;
    }

    @media screen and (max-width: 1366px) {
        top: 49.5%;
        left: 41.1%;
        width: 190px;
        height: 190px;
    }

    @media screen and (max-width: 1280px) {
        top: 50%;
        left: 40.3%;
        width: 180px;
        height: 180px;
    }

    @media screen and (max-width: 1024px) {
        width: 150px;
        height: 150px;
        top: calc(50% - 4px);
        left: 36.7%;
    }

    @media screen and (max-width: 1024px) and (max-height: 1366px) {
        width: 300px;
        height: 300px;
        top: calc(50% + 45px);
        left: 36.7%;
    }

    @media screen and (max-width: 1024px) and (max-height: 900px) {
        width: 150px;
        height: 150px;
        top: calc(50% - 4px);
        left: 36.7%;
    }

    @media screen and (max-width: $tableWidth) {
        width: 180px;
        height: 180px;

        top: calc(50% + 80px);
        left: 50%;
        transform: translate(-50%,-50%);

    }

    @media screen and (max-height: 568px) {
        width: 140px;
        height: 140px;
    }

    @media screen and (max-width: $tableWidth) and (max-height: 1024px) {
        width: 250px;
        height: 250px;
    }

    @media screen and (max-width: $tableWidth ) and (max-height: 860px) {
        width: 180px;
        height: 180px;
        top: calc(50% + 60px);
    }

    @media screen and (max-width: $tableWidth ) and (max-height: 667px) {
        width: 160px;
        height: 160px;
        top: calc(50% + 50px);
    }

    @media screen and (max-width: $tableWidth ) and (max-height: 568px) {
        width: 140px;
        height: 140px;
        top: calc(50% + 40px);
    }
    

    background: none;
    border: none;
    border-radius: 48%;
    // backdrop-filter: blur(8px);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.main-screen__hide-int {
    .login-btn {
        position: absolute;

        top: calc(50% + 50px);
        left: 49.2%;
        transform: translate(-50%, -50%);
    
        width: 500px;
        height: 500px;

        @media screen and (max-width: 2660px) {
            top: calc(50% - 26px);
            left: 48.9%;
            transform: translate(-50%, -50%);
            width: 300px;
            height: 300px;
        }

        @media screen and (max-width: 2048px) {
            top: calc(50% - 20px);
            left: 48.6%;
        }

        @media screen and (max-width: 1920px) {
            top: calc(50% - 45px);
            left: 48.9%;
            width: 250px;
            height: 250px;
        }

        @media screen and (max-width: 1600px ) {
            top: calc(50% - 65px);
            left: 48.5%;
            width: 200px;
            height: 200px;
        }

        @media screen and (max-width: 1536px ) {
            top: calc(50% - 65px);
            left: 48.5%;
            width: 200px;
            height: 200px;
        }

        @media screen and (max-width: 1440px) {
            top: calc(50% - 79px);
            left: 48.5%;
            width: 190px;
            height: 190px;
        }

        @media screen and (max-width: 1366px) {
            top: calc(50% - 81px);
            left: 48.4%;
        }

        @media screen and (max-width: 1280px) {
            top: calc(50% - 79px);
            left: 48.3%;
        }

        @media screen and (max-width: 1024px) {
            width: 150px;
            height: 150px;
            top: calc(50% - 82px);
            left: 47.8%;
        }
    }
}